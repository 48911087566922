import axios from "../config"

class ExcelService{
    axios;
    constructor(){
        this.axios = axios;
    }
    excelPayment(fromDate,toDate,customerId,typeId,branchId) {
      return this.axios({
        url: "/free/payment",
        method: "GET",
        responseType: "blob",
        params: {
          fromDate,toDate,customerId,typeId,branchId
      }
      });
    }
    excelComission(fromDate,toDate,brockerId,typeId,branchId) {
      return this.axios({
        url: "/free/comission",
        method: "GET",
        responseType: "blob",
        params: {
          fromDate,toDate,brockerId,typeId,branchId
      }
      });
    }
    excelExpenses(fromDate,toDate,etypeId,typeId,branchId) {
      return this.axios({
        url: "/free/expenses",
        method: "GET",
        responseType: "blob",
        params: {
          fromDate,toDate,etypeId,typeId,branchId
      }
      });
    }
}

const service = new ExcelService();
export default service;