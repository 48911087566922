import BasicService from "./BasicService";

class Customer extends BasicService {
  constructor() {
    super();
  }

  //   customers
  deleteSelectedCustomer(customers) {
    let url = `/forcustomers/${customers.forCustomerId}`;
    return this.axios.delete(url).then(request => request.data);
  }
  updateSelectedCustomer(customers) {
    let url = `/forcustomers/${customers.forCustomerId}`;
    return this.axios.put(url, customers).then(request => request.data);
  }
  addSelectedCustomer(newForCustomer) {
    let url = `/forcustomers/`;
    return this.axios.post(url, newForCustomer).then(request => request.data);
  }
  getRelativeByCustomer(customerId) {
    let url = `/forcustomers`;
    return this.axios
      .get(url, {
        params: {
          customerId: customerId
        }
      })
      .then(request => request.data);
  }

  deleteCustomer(customers) {
    let url = `/customers/${customers.customerId}`;
    return this.axios.delete(url, customers).then(request => request.data);
  }
  updateCustomer(customers) {
    let url = `/customers/${customers.customerId}`;
    return this.axios.put(url, customers).then(request => request.data);
  }
  addCustomer(customers) {
    let url = `/customers`;
    return this.axios.post(url, customers).then(request => request.data);
  }
  getCustomer() {
    let url = `/customers`;
    return this.axios.get(url).then(request => request.data);
  }
  getCustomersPage(pageId) {
    let url = `/customers/page`;
    return this.axios.get(url,{params:{
      pageId
    }}).then(request => request.data);
  }
  getBrocker(type) {
    let url = `/payments/brocker`;
    return this.axios.get(url,{
      params:{
        type
      }
    }).then(request => request.data);
  }
  addBrocker(brocker) {
    let url = `/payments/brocker`;
    return this.axios.post(url, brocker).then(request => request.data);
  }
  updateBrocker(brocker) {
    let url = `/payments/brocker/${brocker.brockerId}`;
    return this.axios.put(url, brocker).then(request => request.data);
  }
  deleteBrocker(brocker) {
    let url = `/payments/brocker/${brocker.brockerId}`;
    return this.axios.delete(url).then(request => request.data);
  }
  /**
   *
   * @param {String} data , search data
   * @param {String} searchBy , "phone", "customerName"
   */
  getCustomerBy(data, searchBy) {
    let url = `/customers/search`;
    return this.axios
      .get(url, {
        params: {
          searchBy: searchBy,
          data: data
        }
      })
      .then(response => response.data);
  }

  getGenderType() {
    let url = "/passtypes";
    return this.axios.get(url).then(response => response.data);
  }
}

const customerService = new Customer();
export default customerService;
