<template>
<v-row no-gutters>
        <v-col
          v-for="item in report"
          :key="item.title"
          cols="12"
          md="4"
        >
          <v-card class="ma-1" outlined>
            <v-card-title class="primary white--text"> 
              <h5>{{ item.title }}</h5>
            </v-card-title>
                <v-col
          v-for="itemReport in item.reportList"
          :key="itemReport.custId">
          <v-row no-gutters class="ml-2 mr-2">
            <v-col cols="6" md="6">
              {{itemReport.custName}} ({{itemReport.unitName}})
            </v-col>
            <v-col cols="6" md="6" class="text-right">
              <span>{{itemReport.usdTotal|doubleFormat}} / {{itemReport.mmkTotal|doubleFormat}}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
                <v-divider></v-divider>
            </v-col>
             <v-row no-gutters class="ml-2 mr-2">
            <v-col cols="6" md="6" class="red--text">
              Balance (USD/MMK)
            </v-col>
            <v-col cols="6" md="6" class="text-right red--text">
              <span>{{item.totalUsd|doubleFormat}} / {{item.totalMmk|doubleFormat}}</span>
            </v-col>
            </v-row>
            </v-card>
            </v-col>
            </v-row>
</template>
<script>
import reportService from "../service/ReportService";
export default {
  data: () => ({
    report:[]
  }),
   props: {},
  mounted: function() {
    this.getReport();
  },
  methods: {
    getReport: function () {
      reportService
        .getDebCredit()
        .then((data) => {
          console.log(data);
            this.report.push(...data);
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
  },
  watch: {},
  components: {}
};
</script>
<style scoped>
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    width: 100%;
    padding: 0px;
}
</style>