<template>
  
      <v-row class="tempColor" no-gutters>
        <v-col cols="12">
      <v-toolbar dark color="primary pl-2 pr-2" dense>
        <v-toolbar-title>Brocker</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('closeBrockerDialog')">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-toolbar>
    </v-col>
      <v-col cols="12" md="6">
        <v-row no-gutters class="pa-2">
          <v-col cols="12" md="12">
         <v-simple-table
      :fixed-header="true"  class="elevation-1" dense height="90vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center white--text primary">No.</th>
            <th class="text-center white--text primary"><v-divider vertical></v-divider></th>
            <th class="text-center white--text primary">Name</th>
            <th class="text-center white--text primary"><v-divider vertical></v-divider></th>
            <th class="text-center white--text primary">Phone</th>
            <th class="text-center white--text primary"><v-divider vertical></v-divider></th>
            <th class="text-center white--text primary">Remark</th>
            <th class="text-center white--text primary"><v-divider vertical></v-divider></th>
            <th class="text-center white--text primary">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in brockerList"  :key="index" @click="selectOneClick(item)"
              :style="{
                backgroundColor:
                  item.brockerId == selectedOne.brockerId
                    ? '#def3ff'
                    : 'transparent',
              }">
            <td class="text-center">{{ index+1 }}</td>
            <td class="text-center"><v-divider vertical></v-divider></td>
            <td class="text-center">{{ item.name }}</td>
            <td class="text-center"><v-divider vertical></v-divider></td>
            <td class="text-left">{{ item.phone }}</td>
            <td class="text-center"><v-divider vertical></v-divider></td>
            <td class="text-center">{{ item.remark }}</td>
            <td class="text-center"><v-divider vertical></v-divider></td>
            <td class="text-center"><v-btn
                    small
                    icon
                    color="green"
                    @click="clickEdit(item)"
                  >
                    <v-icon>edit</v-icon></v-btn
                  >
                  <v-btn
                    small
                    icon
                    color="red"
                    @click="clickDelete(item)"
                  >
                    <v-icon>delete</v-icon></v-btn
                  ></td>
          </tr>
          <v-divider></v-divider>
        </tbody>
      </template>
 </v-simple-table>
  </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="mt-5 pr-3">
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <v-text-field label="Name" v-model="brocker.name" dense outlined filled></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-text-field label="Phone" v-model="brocker.phone" dense outlined filled></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
            <v-textarea label="Remark" rows="2" v-model="brocker.remark"  dense outlined filled></v-textarea>
            </v-col>
             <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" @click="clickSaveOrUpdate()">{{saveOrupdate}}</v-btn>
            </v-col>
    </v-row>
  </v-col>
  <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Delete - {{this.tempbrocker.name}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red" text @click="clickDeleteDialog()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-row>
</template>
<script>
import customerService from "../service/CustomerService";
  export default {
    data () {
      return {
        selectedOne:{},
        brockerList:[],
        brocker:{},
        tempbrocker:{},
        saveOrupdate:"SAVE",
        deleteDialog:false
      }
    },
    props:{brockertype:String},
    mounted:function(){
      this.getPaymentListMethod();
    },
    methods:{
      clickDeleteDialog:function(){
           customerService
        .deleteBrocker(this.tempbrocker)
        .then((response) => {
           this.deleteDialog = false;
          this.getPaymentListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      },
      clickDelete:function(item){
        this.tempbrocker = Object.assign({},item);
        this.deleteDialog = true;
      },
      clickEdit:function(item){
        this.brocker = Object.assign({},item);
        this.saveOrupdate = "UPDATE";
      },
      clickSaveOrUpdate:function(){
        this.brocker.type = this.brockertype;
        if(this.saveOrupdate == "SAVE"){
        this.brocker.brockerId = 0;
            customerService
        .addBrocker(this.brocker)
        .then((response) => {
          this.brocker = {};
          this.getPaymentListMethod();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
        }else{
 customerService
        .updateBrocker(this.brocker)
        .then((response) => {
          this.brocker = {};
          this.getPaymentListMethod();
          this.saveOrupdate = "SAVE";
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
        }
      },
      selectOneClick: function (item) {
      this.selectedOne = Object.assign({},item);
    },
    getPaymentListMethod: function () {
      customerService
        .getBrocker(this.brockertype)
        .then((response) => {
          this.brockerList.splice(0);
          this.brockerList.push(...response);
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    },
    watch:{}
  }
</script>