import BasicService from "./BasicService";

class PaymentService extends BasicService {
  constructor() {
    super();
  }
  getPayment(fromDate,toDate,customerId,typeId,branchId) {
    let url = `/payments`;
    return this.axios.get(url,{
      params:{
        fromDate,toDate,customerId,typeId,branchId
      }
    }).then(response => response.data);
  }
  savePayment(payment) {
    let url = `/payments`;
    return this.axios.post(url,payment).then(response => response.data);
  }
  updatePayment(payment) {
    let url = `/payments/${payment.paymentId}`;
    return this.axios.put(url,payment).then(response => response.data);
  }
  deletePayment(payment) {
    let url = `/payments/${payment.paymentId}/${payment.tranDto.transactionId}`;
    return this.axios.delete(url).then(response => response.data);
  }

  getPaymentCom(fromDate,toDate,brockerId,typeId,branchId) {
    let url = `/payments/bro`;
    return this.axios.get(url,{
      params:{
        fromDate,toDate,brockerId,typeId,branchId
      }
    }).then(response => response.data);
  }
  addPaymentCom(payment) {
    let url = `/payments/bro`;
    return this.axios.post(url,payment).then(response => response.data);
  }
  updatePaymentBro(payment) {
    let url = `/payments/bro/${payment.paymentId}`;
    return this.axios.put(url,payment).then(response => response.data);
  }
}
const airlineService = new PaymentService();
export default airlineService;
