import BasicService from "../service/BasicService";

class ReportService extends BasicService {
  constructor() {
    super();
  }

  getCustomerReport({ fromDate, toDate, customerId = 0, type }) {
    let url = `/reports/customer`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          customerId: customerId,
          type: type
        }
      })
      .then(response => response.data);
  }

  getCustomerDetailReport({ fromDate, toDate, customerId = 0, type }) {
    let url = `/reports/customers/${customerId}/detail`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          type: type
        }
      })
      .then(response => response.data);
  }

  getAirlineReport({ fromDate, toDate, type ,airlineId }) {
    let url = `/reports/airline`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          type: type,
          airlineId : airlineId
        }
      })
      .then(response => response.data);
  }

  getAirlineDetailReport({ fromDate, toDate, airlineId }) {
    let url = `/reports/airlineDetail`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          airlineId: airlineId
        }
      })
      .then(response => response.data);
  }

  getSectorReport({ fromDate, toDate, type }) {
    let url = `/reports/sector`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          type: type
        }
      })
      .then(response => response.data);
  }

  getAirlineWithSectorReport({ fromDate, toDate, type }) {
    let url = `/reports/airlineSector`;
    return this.axios
      .get(url, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          type: type
        }
      })
      .then(response => response.data);
  }
  getIncomeReport(fromDate, toDate, type,typeId,itypeId,branchId) {
    let url = `/reports/income`;
    return this.axios
      .get(url, {
        params: {
          fromDate, toDate, type,typeId,itypeId,branchId
        }
      })
      .then(response => response.data);
  }
  getExpenseReport(fromDate, toDate, type,typeId,etypeId,branchId) {
    let url = `/reports/expense`;
    return this.axios
      .get(url, {
        params: {
          fromDate, toDate, type,typeId,etypeId,branchId
        }
      })
      .then(response => response.data);
  }
  getBillReport(fromDate, toDate, type,typeId,branchId) {
    let url = `/reports/bill`;
    return this.axios
      .get(url, {
        params: {
          fromDate, toDate, type,typeId,branchId
        }
      })
      .then(response => response.data);
  }
  getDebCredit() {
    let url = `/reports/debcredit`;
    return this.axios
      .get(url)
      .then(response => response.data);
  }
  getReportUser(fromDate, toDate,userId, status) {
    let url = `/reports/user`;
    return this.axios
      .get(url, {
        params: {
          fromDate, toDate,userId, status
        }
      })
      .then(response => response.data);
  }
}
const reportService = new ReportService();
export default reportService;
