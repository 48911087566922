<template>
  <v-row no-gutters class="ml-2 mr-2">
    <v-col cols="12" md="12">
      <v-card outlined class="mt-3">
        <v-row no-gutters class="ml-2 mt-5">
          <v-col cols="5" md="2">
            <v-menu
              v-model="fromMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromPicker"
                @input="fromMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5" md="2" class="pl-2">
            <v-menu
              v-model="toMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toPicker"
                @input="toMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="2" class="pl-2">
            <v-combobox
              v-model="allcashtype"
              :items="allcashtypeList"
              label="Cash Type"
              item-text="typeName"
              return-object
              required
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6" md="2" class="pl-1">
          <v-combobox
            v-model="branch"
            :items="branchList"
            label="Branch"
            item-text="description"
            return-object
            required
            dense
          ></v-combobox>
        </v-col>
          <v-col cols="2" md="1" class="pl-2 pt-1">
            <v-btn icon class="primary" @click="getSearch()">
              <v-icon color="white"> search </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="8">
            <v-radio-group v-model="dateType" row hide-details dense>
              <v-radio label="Day" value="date"></v-radio>
              <v-radio label="Month" value="month"></v-radio>
              <v-radio label="Year" value="year"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4" class="pr-2">
            <v-alert border="left" color="primary" dark dense>
              Out = {{ report.totalOut|doubleFormat }}, Commission = {{report.totalOutCommission|doubleFormat}} 
              <br>In = {{ report.totalIn |doubleFormat}}, Commission = {{report.totalInCommission|doubleFormat}}
            </v-alert>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-row no-gutters>
        <v-col
          v-for="(item, index) in report.reportList"
          :key="index"
          cols="12"
          md="6"
        >
          <v-card class="ma-1" outlined>
            <v-card-title class="primary white--text">
              <v-icon color="white" class="mr-2">event</v-icon>
              <h5>{{ item.date }}</h5>
            </v-card-title>
            <v-row no-gutters class="ml-2 mr-2">
                    <v-col cols="4" md="4">
                      Cash Type
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      Out Amount
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      Out Com
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      In Amount
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      In Com
                    </v-col>
                  </v-row>
                <v-col
                  v-for="(bill, index) in item.billList"
                  :key="index"
                  cols="12"
                  md="12"
                >
                  <v-row no-gutters class="ml-2 mr-2">
                    <v-col cols="4" md="4">
                      {{ bill.typeName }}
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      {{ bill.outBalance|doubleFormat  }}
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      {{ bill.outCommission|doubleFormat  }}
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      {{ bill.inBalance|doubleFormat  }}
                    </v-col>
                    <v-col cols="2" md="2" class="text-right">
                      {{ bill.inCommission|doubleFormat  }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider></v-divider>
            <v-row no-gutters class="ml-2 mr-2">
            <v-col cols="4" md="4"> 
Total Amount
            </v-col>
            <v-col cols="2" md="2" class="text-right">{{ item.toutBalance|doubleFormat }}</v-col>
            <v-col cols="2" md="2" class="text-right">{{ item.toutCommission|doubleFormat }}</v-col>
            <v-col cols="2" md="2" class="text-right">
             {{ item.tinBalance|doubleFormat }}
            </v-col>
            <v-col cols="2" md="2" class="text-right">
             {{ item.tinCommission|doubleFormat }}
            </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import billService from "../service/BillService";
import reportService from "../service/ReportService";
export default {
  data: () => ({
    fromPicker: new Date().toISOString().substr(0, 10),
    toPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    toMenu: false,
    fromDate: "",
    toDate: "",
    allcashtypeList: [],
    allcashtype: {},
    dateType: "date",
    report: {},
    branchList:[],
    branch:{}
  }),
  props: {},
  mounted: function () {
    this.fromDate = this.formatDate(this.fromPicker);
    this.toDate = this.formatDate(this.toPicker);
    this.getCashListMethod();
    this.getBranchListMethod();
  },
  methods: {
    getBranchListMethod:function(){
billService
        .getBranch()
        .then((response) => {
          var tempObj = {};
          tempObj.branchId = 0;
          tempObj.description = "All";
          this.branchList.push(tempObj);
          this.branchList.push(...response);
          this.branch = this.branchList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    formatDate(fromPicker) {
      const [year, month, day] = fromPicker.split("-");
      return `${day}-${month}-${year}`;
    },
    getCashListMethod: function () {
      billService
        .getCashTypes()
        .then((response) => {
          this.allcashtypeList.splice(0);
          var tempObj = {};
          tempObj.typeId = 0;
          tempObj.typeName = "ALL";
          this.allcashtypeList.push(tempObj);
          this.allcashtypeList.push(...response);
          this.allcashtype = this.allcashtypeList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getSearch: function () {
      reportService
        .getBillReport(
          this.fromDate,
          this.toDate,
          this.dateType,
          this.allcashtype.typeId,
          this.branch.branchId
        )
        .then((response) => {
          this.report = Object.assign({}, response);
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
  },
  watch: {
    fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
    toPicker() {
      this.toDate = this.formatDate(this.toPicker);
    },
  },
  components: {},
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 0px;
}
</style>