<template>
  <v-row no-gutters class="mt-3 ml-2">
    <v-col cols="12" md="8">
      <v-col cols="12" md="12">
        <v-row no-gutters class="ml-2">
          <v-col cols="5" md="2">
            <v-menu
              v-model="fromMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromPicker"
                @input="fromMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5" md="2" class="pl-2">
            <v-menu
              v-model="toMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toPicker"
                @input="toMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="2" class="pl-2">
            <v-combobox
              v-model="allcustomer"
              :items="allcustomerList"
              label="Customers"
              item-text="customerName"
              return-object
              required
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6" md="2" class="pl-2">
            <v-combobox
              v-model="allcashtype"
              :items="allcashtypeList"
              label="Cash Type"
              item-text="typeName"
              return-object
              required
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6" md="2" class="pl-1">
          <v-combobox
            v-model="branch"
            :items="branchList"
            label="Branch"
            item-text="description"
            return-object
            required
            dense
          ></v-combobox>
        </v-col>
          <v-col cols="2" md="1" class="pl-2 pt-1">
            <v-btn icon class="primary" @click="getSearch()" small>
              <v-icon color="white"> search </v-icon>
            </v-btn>
            <v-icon @click="downloadExcel()" class="ml-3" color="black">archive</v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <v-simple-table
          :fixed-header="true"
          dense
          class="elevation-1"
          height="80vh"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center white--text primary">No.</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Receive Date</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Customer</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Type</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">One Unit</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                 <th class="text-center white--text primary">Unit Amount</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Amount</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Code</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Remark</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Date</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in paymentList"
                :key="index"
                @click="selectOneClick(item)"
                :style="{
                  backgroundColor:
                    item.paymentId == selectedOne.paymentId
                      ? '#def3ff'
                      : 'transparent',
                }"
              >
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.requestedDate }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-left">{{ item.custName }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-left primary--text">
                  {{ item.tranDto.typeName }}({{ item.tranDto.unit }})
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right">
                  {{ item.tranDto.oneUnit | doubleFormat }}
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right">
                  {{ item.tranDto.unitAmount | doubleFormat }}
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right" v-if="item.tranDto.amount>0">
                  <h4>{{ item.tranDto.amount | doubleFormat }}</h4>
                </td>
                <td class="text-right red--text" v-else>
                  <h4>{{ item.tranDto.amount | doubleFormat }}</h4>
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.voucherCode }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.tranDto.remark }}</td>
                
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.tranDto.date }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">
                  <v-btn
                    small
                    icon
                    color="green"
                    @click="clickEditPayment(item)"
                    v-if="uiAccept.receiptedit"
                  >
                    <v-icon>edit</v-icon></v-btn
                  >
                  <v-btn
                    small
                    icon
                    color="red"
                    @click="clickDeletePayment(item)"
                    v-if="uiAccept.receiptdelete"
                  >
                    <v-icon>delete</v-icon></v-btn
                  >
                </td>
              </tr>
              <v-divider></v-divider>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-col>
    <v-col cols="12" md="4">
      <v-row no-gutters class="mt-12 ml-2 mr-2">
        <v-col cols="4" md="4">
<v-menu
              v-model="receiveMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="receiveDate"
                  label="Receive Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                  filled
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="receivePicker"
                @input="receiveMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="8" md="8" class="pl-2">
          <v-combobox
            v-model="customer"
            :items="customerList"
            label="Customers"
            item-text="customerName"
            return-object
            required
            dense
            outlined
            filled
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="12">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-combobox
                v-model="cashtype"
                :items="cashtypeList"
                label="Cash Type"
                item-text="typeName"
                return-object
                required
                dense
                outlined
                filled
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="6">
              <center>
                <span class="red--text"
                  ><h3>{{ balance | doubleFormat }} ({{cashtype.unit}}) </h3></span
                >
              </center>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" md="6">
                <v-text-field
                  v-model.number="oneUnit"
                  label="OneUnit"
                  type="number"
                  dense
                  outlined
                  filled
                />
              </v-col>
              <v-col cols="6" md="6" class="pl-3 green--text">
                <!-- <v-text-field
            label="Amount"
            v-model.number="amount"
            type="number"
            dense
            outlined
            filled
          ></v-text-field> -->
          <center>
          <h4>{{amount|doubleFormat}}(mmk)</h4>
          </center>
              </v-col>
        <v-col cols="6" md="6">
          <v-text-field
                  v-model.number="unitAmount"
                  label="UnitAmount"
                  type="number"
                  dense
                  outlined
                  filled
                  disabled
                />
        </v-col>
        <v-col cols="6" md="6" class="pl-2">
          <v-text-field
            label="Voucher Code"
            rows="2"
            v-model="voucherCode"
            dense
            outlined
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            label="Remark"
            rows="2"
            v-model="remark"
            dense
            outlined
            filled
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="text-right">
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <v-radio-group v-model="rgPayment" row>
                <v-radio label="Get" value="get"></v-radio>
                <v-radio label="Paid" value="pay"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn color="primary" @click="clickSaveOrUpdate()">{{
                saveOrupdate
              }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
            <h3>Total Balance = {{totalBalance|doubleFormat}}</h3>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Delete </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red" text @click="clickDeleteDialog()"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import paymentService from "../service/PaymentService";
import billService from "../service/BillService";
import customerService from "../service/CustomerService";
import excelService from "../service/ExcelService";
export default {
  data: () => ({
    paymentList: [],
    selectedOne: {},
    fromPicker: new Date().toISOString().substr(0, 10),
    toPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    toMenu: false,
    fromDate: "",
    toDate: "",
    allcustomerList: [{customerId:0,customerName:"ALL"}],
    allcustomer: {},
    allcashtypeList: [],
    allcashtype: {},
    amount: 0,
    remark: "",
    saveOrupdate: "SAVE",
    customerList: [],
    customer: {},
    cashtypeList: [],
    cashtype: {},
    payment: {},
    deleteDialog: false,
    balance: 0,
    rgPayment:"get",
    branchList:[],
    branch:{},
    voucherCode:"",
    receivePicker: new Date().toISOString().substr(0, 10),
    receiveMenu: false,
    receiveDate: "",
    totalBalance:0,
    oneUnit:0,
    unitAmount:0,
    tempAllcustomerList:[],
    customerPageId:0,
    uiAccept:{},
  }),
  props: {},
  mounted: function () {
    this.uiAccept = this.$store.state.uiAccept;
    this.fromDate = this.formatDate(this.fromPicker);
    this.toDate = this.formatDate(this.toPicker);
    this.receiveDate = this.formatDate(this.receivePicker);
    this.getPaymentListMethod();
    this.getCashListMethod();
    this.getBranchListMethod();
  },
  methods: {
    downloadExcel:function(){
      excelService.excelPayment(this.fromDate,
          this.toDate,
          this.allcustomer.customerId,
          this.allcashtype.typeId,
          this.branch.branchId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payment.xlsx");
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
    },  
    getBranchListMethod:function(){
billService
        .getBranch()
        .then((response) => {
          var tempObj = {};
          tempObj.branchId = 0;
          tempObj.description = "All";
          this.branchList.push(tempObj);
          this.branchList.push(...response);
          this.branch = this.branchList[0];
          this.getSearch();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    clickDeleteDialog: function () {
      this.deleteDialog = false;
      paymentService
        .deletePayment(this.payment)
        .then((response) => {
          this.getBalanceMethod();
          this.getSearch();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    selectOneClick: function (item) {
      this.selectedOne = Object.assign({}, item);
    },
    clickDeletePayment: function (item) {
      this.payment = Object.assign({}, item);
      this.deleteDialog = true;
    },
    clickEditPayment: function (item) {
      this.receiveDate = item.requestedDate;
      this.receivePicker = this.formatPicker(this.receiveDate);
      this.payment = Object.assign({}, item);
      var tempPayment = {};
      tempPayment.customerId = item.customerId;
      tempPayment.customerName = item.custName;
      this.customer = Object.assign({}, tempPayment);
      var tempType = {};
      tempType.typeId = item.tranDto.typeId;
      tempType.typeName = item.tranDto.typeName;
      tempType.unit = item.tranDto.unit;
      this.cashtype = Object.assign({}, tempType);
      this.voucherCode = item.voucherCode;
      if(item.tranDto.amount>0){
          this.rgPayment = "get";
          this.amount = item.tranDto.amount;
      }else{
        this.rgPayment = "pay";
        this.amount = -item.tranDto.amount;
      }
      this.oneUnit = item.tranDto.oneUnit;
      this.unitAmount = item.tranDto.unitAmount;
      this.remark = item.tranDto.remark;
      this.saveOrupdate = "UPDATE";
      
    },
    clickSaveOrUpdate: function () {
      if (Number(this.amount) > 0) {
        if (this.saveOrupdate != "UPDATE") {
          var tempObj = {};
          tempObj.customerId = this.customer.customerId;
          var tranDto = {};
          tranDto.typeId = this.cashtype.typeId;
          tranDto.amount = this.amount;
          if(this.rgPayment=="pay"){
            tranDto.amount = -this.amount;
          }
          tranDto.oneUnit = this.oneUnit;
          tranDto.unitAmount = this.unitAmount;
          tranDto.remark = this.remark;
          tempObj.tranDto = tranDto;
          tempObj.requestedDate = this.receiveDate;
          tempObj.voucherCode = this.voucherCode;
          paymentService
            .savePayment(tempObj)
            .then((response) => {
              this.getBalanceMethod();
              this.getSearch();
              this.saveOrupdate = "SAVE";
              this.amount = 0;
              this.oneUnit = 0;
              this.remark = "";
              this.voucherCode = "";
            })
            .catch((error) => {
              this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
            });
        } else {
          this.payment.customerId = this.customer.customerId;
          this.payment.tranDto.typeId = this.cashtype.typeId;
          this.payment.tranDto.amount = this.amount;
          if(this.rgPayment=="pay"){
            this.payment.tranDto.amount = -this.amount;
          }
          this.payment.tranDto.oneUnit = this.oneUnit;
          this.payment.tranDto.unitAmount = this.unitAmount;
          this.payment.tranDto.remark = this.remark;
          this.payment.requestedDate = this.receiveDate;
          this.payment.voucherCode = this.voucherCode;
          paymentService
            .updatePayment(this.payment)
            .then((response) => {
              this.getBalanceMethod();
              this.getSearch();
              this.saveOrupdate = "SAVE";
              this.amount = 0;
              this.oneUnit = 0;
              this.remark = "";
              this.voucherCode = "";
            })
            .catch((error) => {
              this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
            });
        }
      }
    },
    getCashListMethod: function () {
      billService
        .getCashTypes()
        .then((response) => {
          this.cashtypeList.push(...response);
          this.cashtype = this.cashtypeList[0];
          this.allcashtypeList.splice(0);
          var tempObj = {};
          tempObj.typeId = 0;
          tempObj.typeName = "ALL";
          this.allcashtypeList.push(tempObj);
          this.allcashtypeList.push(...response);
          this.allcashtype = this.allcashtypeList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getPaymentListMethod: function () {
      this.customerPageId = this.customerPageId+1;
      customerService
        .getCustomersPage(this.customerPageId)
        .then((response) => {
          this.customerList.push(...response);
          this.customer = this.customerList[0];
          //this.allcustomerList.splice(0);
          // var tempObj = {};
          // tempObj.customerId = 0;
          // tempObj.customerName = "ALL";
          // this.allcustomerList.push(tempObj);
          this.allcustomerList.push(...response);
          this.allcustomer = this.allcustomerList[0];
          if(response.length==200){
            this.getPaymentListMethod();
          }
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    formatDate(fromPicker) {
      const [year, month, day] = fromPicker.split("-");
      return `${day}-${month}-${year}`;
    },
    formatPicker(receivedDate) {
      const [day, month,year ] = receivedDate.split("-");
      return `${year}-${month}-${day}`;
    },
    getSearch: function () {
      paymentService
        .getPayment(
          this.fromDate,
          this.toDate,
          this.allcustomer.customerId,
          this.allcashtype.typeId,
          this.branch.branchId
        )
        .then((data) => {
          this.paymentList.splice(0);
          var tempSize = data.length;
          for (var i = 0; i < tempSize; i++) {
            var tempObj = data[i];
            let index = i + 1;
            if (index == tempSize) {
              this.totalBalance = tempObj.total;
            } else {
              this.paymentList.push(tempObj);
            }
          }
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getBalanceMethod: function () {
      billService
        .getBalance(this.cashtype.typeId)
        .then((response) => {
          this.balance = response;
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
  },
  watch: {
    fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
    toPicker() {
      this.toDate = this.formatDate(this.toPicker);
    },
    receivePicker() {
      this.receiveDate = this.formatDate(this.receivePicker);
    },
    cashtype() {
      if(this.cashtype.unitAmount != undefined){
          this.unitAmount = this.cashtype.unitAmount;
      }
      this.amount = Number(this.oneUnit)*Number(this.unitAmount);
      this.getBalanceMethod();
    },
    oneUnit(){
        this.amount = Number(this.oneUnit)*Number(this.unitAmount);
    },
  },
  components: {},
};
</script>
<style scoped>
.v-data-table td,
.v-data-table th {
  padding: 0 0px !important;
}
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 0px;
}
</style>